<template>
  <div>
    <el-select v-model="listQuery.stationId" placeholder="選擇充電站(All)"   class="filter-item" clearable size="small"  >
            <el-option v-for="item in serviceOptions" :key="item.id" :label="item.name" :value="item.id"   />
           </el-select>
    <el-select v-model="listQuery.connectorState" size="small" class="filter-item" style="width:10%" placeholder="所有">
            <el-option label="所有" value="" />
            <el-option label="待機中" value="Available" />
            <el-option label="充電中" value="Charging" />
            <el-option label="完成中" value="Finishing" />
            <el-option label="佔用中" value="Preparing" />
            <el-option label="離線中" value="Disconnect" />
            <el-option label="未知" value="Unknown" />
            <el-option label="異常" value="Faulted" />
          </el-select>
<el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="connectorInfo_Table" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
    >
      <!--<el-table-column align="center" prop="id" label="編號" sortable />-->
      <!--<el-table-column align="center" prop="name" v-bind:label="$t('Name')" sortable />-->
      <el-table-column align="center" prop="chargeID" label="充電Id" v-if="false" sortable />
      <el-table-column align="center" prop="stationID" label="充電站Id" v-if="false"  sortable />
      <el-table-column align="center" prop="stationName" label="充電站" sortable />
      <el-table-column align="center" prop="pointName" label="充電樁" sortable />
      <el-table-column align="center" prop="connectorId" label="充電槍" sortable />
      <el-table-column align="center" prop="connectorStateS" label="充電槍狀態" sortable />
      <el-table-column align="center" prop="starton" label="充電開始時間" sortable />
      <el-table-column align="center" prop="endon" label="充電時間" sortable />
      <el-table-column align="center" prop="current" label="目前電流量" sortable />
      <el-table-column align="center" prop="totalpower" label="累積充電度數" sortable />
      <!--el-table-column align="center" prop="" label="充電狀態查詢">
        <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-search" @click="queryStatus(scope.row)" />
        </template>
       </el-table-column -->
       <el-table-column align="center" prop="" label="遠端控制" >
        <template slot-scope="scope">
          <el-button v-if="scope.row.connectorState != '離線中' && scope.row.connectorState != '未知'" type="success" size="mini" icon="el-icon-setting"  @click="cmdSelect(scope.row)" />
          <!-- button v-else-if="scope.row.connectorState === '待機中'" type="success" size="mini" icon="el-icon-setting" @click="cmdSelect(scope.row)" / -->
        </template>
       </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getSummaryInfo" />
        <el-dialog width="700px"  :visible.sync="dialogRemoteControl" @close="dialogOnClose()" >

       <el-row align="left" style="font-size:18px"  >遠端控制  </el-row>

          <el-divider content-position="left" style="font-size:16px">充電樁資訊</el-divider>
          <el-row align="left" style="font-size:16px;padding-top: 10px;"  v-if="this.dialogData"> <el-col align="left" :span="6"> 充電站</el-col><el-col align="left" :span="6"> {{ this.dialogData.stationName }} </el-col> </el-row>
          <el-row align="left" style="font-size:16px;padding-top: 10px;"  v-if="this.dialogData"> <el-col align="left" :span="6">充電樁</el-col><el-col align="left" :span="15" > {{ this.dialogData.pointName }}
          <el-button  size="small" type="info" class="el-icon-eleme" @click="statusCheck()" align="right"  style="margin-left: 5px;" v-if="this.dialogData.connectorState != 'Disconnect' && this.dialogData.connectorStateS != '未知' && this.dialogData.connectorState != 'Charging'" >充電樁重啟</el-button></el-col></el-row>
          <div style="text-align: center">

        </div>
          <!--
          <el-row align="center">
            <el-col align="left" span="6">
          <input type="radio" v-model="cmdType" value="start"  @click="alertMessage('1')">
          <label for="start">開始充電</label>
          </el-col>
          <el-col align="left" span="6">
          <input type="radio" v-model="cmdType" value="stop"  @click="alertMessage('2')">
          <label for="stop">停止充電</label>
          </el-col>
          </el-row>
          <el-row align="center">
            <el-col span="6" align="left">
          <input type="radio" v-model="cmdType" value="restartPoint"  @click="alertMessage('3')">
          <label for="restartPoint">充電樁重啟</label>
          </el-col>
          <el-col span="6" align="left">
          <input type="radio" v-model="cmdType" value="unlockConnector"  @click="alertMessage('4')">
          <label for="unlockConnector">解鎖充電槍</label>
          </el-col>
          </el-row>
          <el-row align="center">
            <el-col span="6" align="left">
          <input type="radio" v-model="cmdType" value="changeOn"  @click="alertMessage('5')">
          <label for="changeOn">開啟充電槍的可用性</label>
          </el-col>
          <el-col  span="6" align="left">
          <input type="radio" v-model="cmdType" value="changeOff"  @click="alertMessage('6')">
          <label for="changeOff">關閉充電槍的可用性</label>
          </el-col>
          </el-row>
          <el-row> ></el-row-->

          <el-divider content-position="left" style="font-size:16px">充電槍資訊</el-divider>
          <el-row align="left" style="font-size:16px"  v-if="this.dialogData"> <el-col align="left" :span="6">充電槍編號</el-col><el-col align="left" :span="6"> {{ this.dialogData.connectorId }}</el-col></el-row>
<div v-if="this.dialogData">
<el-row :gutter="20" style="text-align: left; padding-top: 10px;padding-left: 150px;"  v-if="this.dialogData.connectorState != 'Disconnect' && this.dialogData.connectorState != 'Unknown'">  
    <el-radio-group v-model="cmdType" size="small" @change="radioChange" >
      <el-col :span="12" >
     <el-radio  label="start" border class="custom-radio" v-if="this.dialogData.connectorState != 'Charging'">開始充電</el-radio>
     <el-radio  label="start" border class="custom-radio" v-else   disabled>開始充電</el-radio>
     </el-col>
     <el-col :span="12">
     <el-radio  label="stop" border class="custom-radio" v-if="this.dialogData.connectorState === 'Charging'">停止充電</el-radio>
     <el-radio  label="stop" border class="custom-radio" v-else   disabled>停止充電</el-radio>
     </el-col>
    </el-radio-group>
 </el-row>
 <el-row :gutter="20" style="text-align: left; padding-top: 10px;padding-left: 150px;" v-if="this.dialogData.connectorState != 'Disconnect' && this.dialogData.connectorState != 'Unknown'">
    <el-radio-group v-model="cmdType" size="small" @change="radioChange">
     <el-col :span="8">
     <el-radio  label="unlockConnector" border  class="custom-radio" v-if="this.dialogData.haslock  && this.dialogData.connectorState != 'Unavailable'  && this.dialogData.connectorState != 'Charging'" >解鎖充電槍</el-radio>
     <el-radio  label="unlockConnector" border  class="custom-radio" v-else  disabled>解鎖充電槍</el-radio>
          </el-col>
    <el-col :span="8">
     <el-radio  label="changeOn" border class="custom-radio" v-if="this.dialogData.connectorState === 'Unavailable' ">開啟充電槍的可用性</el-radio>
     <el-radio  label="changeOn" border class="custom-radio"  v-else  disabled>開啟充電槍的可用性</el-radio>
    </el-col>
    <el-col :span="8" >
     <el-radio  label="changeOff" border class="custom-radio" v-if="this.dialogData.connectorState != 'Unavailable' ">關閉充電槍的可用性</el-radio>
     <el-radio  label="changeOff" border class="custom-radio"  v-else  disabled>關閉充電槍的可用性</el-radio>
    </el-col>
    </el-radio-group>
  </el-row>
  </div>
          <br>
          <p  style="font:20px" v-if="this.showMsg">{{ this.alertMsg }}  </p>
        <br>
       <div style="text-align: center">
        <el-button size="small" @click="dialogRemoteControl = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="remoteControl(1)">
          確定
        </el-button>
        </div>
      </el-dialog>
  </div>

</template>

<script>
import { ALARM_STATE, PMS_REDIRECT_URL } from '@/const/const.js';

import Pagination from '@/components/Pagination';
import { refreshToken } from "@/utils/auth";
import { mixins } from '@/views/common/mixins.js';
export default {
  // 儀表板
  name: 'control',
  components: {
    Pagination,
  },
  mixins: [mixins],
  data() {
    return {
      //control URL
      targetUrl:undefined,

      //starton:undefined, //
      //endon:undefined, //
      //starus:undefined, //
      //current:undefined, //
      //totalpower:undefined, //
      currentAccount:undefined,
      serviceId:undefined,

      listLoading:false,
      connectorInfo_Table:[],
      chargingInfo_Table:[],
      serviceStation: undefined, //充電站下拉式選單
      serviceOptions:[],//充電站下拉式選單列表
      selectedValue:undefined, //
      radioValue:undefined,
      cmdType:undefined,
      dialogRemoteControl:undefined,
      dialogData:undefined, //
      showMsg:undefined,
      alertMsg:undefined,
      connectorState:undefined,
      timer: null,
      selectState:undefined,
      total: 0,
      listQuery: {
        serviceId: undefined,
        stationId: undefined,
        connectorState: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 10,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
    };
  },
  async created() {
    
    //console.log("this.serviceOptions",this.serviceOptions);
    this.PMS_REDIRECT_URL = PMS_REDIRECT_URL
    this.ALARM_STATE = ALARM_STATE
    if(this.$route.params.connectorState != ""){
      this.connectorState=this.$route.params.connectorState
      this.listQuery.connectorState=this.$route.params.connectorState
    }
    else
    {
      this.connectorState=""
      this.listQuery.connectorState=""
    }
    this.currentAccount=this.$store.state.currentAccount.serviceInfo.name
    this.serviceId=this.$store.state.currentAccount.serviceInfo.id
    this.listQuery.serviceId=this.$store.state.currentAccount.serviceInfo.id

    /* Dashboard.vue route過來的參數 by企分會議需求 */
    if(this.$route.params.serviceId != undefined) {
      this.serviceId = this.$route.params.serviceId
      this.listQuery.serviceId=   this.$route.params.serviceId
    }
    if(this.$route.params.stationId != undefined) {
      this.serviceStation = this.$route.params.stationId
      this.listQuery.stationId  = Number(this.$route.params.stationId) 
      
      //const selectedOption = this.serviceOptions.find(option => option.value === this.listQuery.stationId);
      //console.log("this.listQuery.stationId ",this.listQuery.stationId );
     // this.listQuery.stationId  = selectedOption.label
    }
    else
    {
      this.listQuery.stationId  = undefined
    }
    /* Dashboard.vue route過來的參數 by企分會議需求 */

    refreshToken();
    //this.getSummaryInfo();
    this.timer = setInterval( this.getSummaryInfo , 10000 );
    this.getServiceOptions();
  
  },
  beforeDestroy() {
    //stopRefreshToken();
    clearInterval(this.timer);
  },
  mounted() {
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.stationId': function(val) {
      this.listQuery.page = 0
      if(val == "" &&  this.listQuery.stationId != undefined)
      {
        this.listQuery.stationId = undefined
      }
      else
      {
        this.resetPage(this.getSummaryInfo)
      }
      
    },
    
    'listQuery.connectorState': function(val) {
      this.listQuery.page = 0
      if(val == ""  &&  this.listQuery.connectorState != undefined)
      {
        this.listQuery.connectorState = undefined
      }
      else
      {
        this.resetPage(this.getSummaryInfo)
      }
     // this.resetPage(this.getSummaryInfo)
    },

  },
  methods: {
    getSummaryInfo() {

      this.listQuery.page = 0
      if(this.listQuery.connectorState == "Unknown"){
        this.connectorState = "";
      } 
      else {
        this.connectorState = this.listQuery.connectorState;
      }
      
      this.targetUrl =  "/api/v1/remote/remotechargelist"
      this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
        console.log("targetUrl :" + this.targetUrl);

        this.connectorInfo_Table = res.data.content
        this.total = res.data.totalElements

       res.data.content.forEach(charge => {

      if(charge.totalpower > 0)
      {
        charge.totalpower = charge.totalpower/1000
      } else if (charge.totalpower < 0)
      {
        charge.totalpower = 0;
      } 
      
      if (charge.connectorState == "Disconnect") {
        charge.connectorStateS  = "離線中";
      } else if (charge.connectorState == "Available") {
        charge.connectorStateS  = "待機中";
      } else if (charge.connectorState == "Charging") {
        charge.connectorStateS   = "充電中";
      } else if (charge.connectorState == "Suspended") {
        charge.connectorStateS   = "佔用中";
      }
      else if (charge.connectorState == "Finishing") {
        charge.connectorStateS   = "完成中";
      } else if (charge.connectorState == "Unavailable") {
        charge.connectorStateS   = "異常";
      } else if (charge.connectorState == "Preparing") {
        charge.connectorStateS   = "佔用中";
      }
      else if (charge.connectorState == "Faulted") {
        charge.connectorStateS   = "異常";
      }  else {
        charge.connectorState = "Unknown";
        charge.connectorStateS  = "未知";
      }

      if(charge.endon != ""){
      // 獲取 starton 和 endon 的值
        var starton = new Date(charge.starton);
        var endon = new Date(charge.endon);

        // 計算時間差
        var timeDiff = Math.abs(endon - starton);
        //console.log(`時間差距：${timeDiff}`);
        // 轉換為分鐘和秒數
        var hours = Math.floor(timeDiff / (1000 * 60 * 60));
        var minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        // 顯示時間差

        if(hours>0){
          charge.endon = hours + "時" + minutes + "分 "+ seconds + "秒"
        }
        else{
          charge.endon = minutes + "分 "+ seconds + "秒"
        }

        }

        })
        if(this.listQuery.connectorState == "Unknown"  ){
          res.data.content= res.data.content.filter(item => item.connectorState == "Unknown");
          this.connectorInfo_Table = res.data.content
        } 
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {            
          console.log('Error', error.message);
        } 
          console.log(error.config);
      })
    },

    remoteControl(val) {
      //alert(this.cmdType)
      //console.log(this.dialogData.chargeID) // http://localhost:6783/remote/remoteCharge

      //this.targetUrl =""
       if(val== 0){
          this.cmdType = "restartPoint"
       }
       if( this.cmdType == "" || this.cmdType == undefined)
       {
        this.alertMsg="請選擇一項控制功能!"
        this.showMsg=true
       }
       else{
        this.targetUrl ="/api/v1/remote/remoteCharge"
        this.axios.post(this.targetUrl, {
        serviceId: this.serviceId,
        stationId: this.dialogData.stationID,
        chargePointID: this.dialogData.chargeID,
        connectorID: this.dialogData.connectorId,
        ps: this.cmdType,
        userID: ""
      })
      .then(response => {
        // 在這裡處理回應

        if (response.data != null) {
          if(response.data.data.includes("stop")){
            alert(response.data.data +" 停止充電後，請記得拔出充電槍!")
          }else{
            alert(response.data.data)
          }

        console.log(response);
        this.getSummaryInfo();

        }
        this.dialogRemoteControl=false
      }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  })
  this.showMsg = false
  this.cmdType = ""
       }

    },
    cmdSelect(row){
        this.dialogRemoteControl=true
        this.dialogData=row
         },
    alertMessage(val){
          this.showMsg=false
            if(val=="1"){
              this.alertMsg="開始充電前，請確定充電槍已插入!"
              this.showMsg=true
            }
            else if(val=="2"){
              this.alertMsg="停止充電後，請記得拔出充電槍!"
              this.showMsg=true
            }
         },
         radioChange :function(val){
          this.showMsg=false
          if(val=="1"){
              this.alertMsg="開始充電前，請確定充電槍已插入!"
              this.showMsg=true
            }
            else if(val=="2"){
              this.alertMsg="停止充電後，請記得拔出充電槍!"
              this.showMsg=true
            }

         },
         dialogOnClose(){
          this.cmdType=""
         },
         statusCheck(){
          if(this.dialogData.connectorState == "Preparing"){
            this.$confirm('提示', {
              message: '充電樁目前充電中不可重啟，是否強制重啟?',
              confirmButtonText: '確認',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.remoteControl(0)
            }).catch(()=> {
              console.log('cancel reset charge point')
            })
          }
      //alert(0)
         //api/v1/reset/status_check?chargePointId=dUmRB4NVy3kFq7M1WyZ6SECKY
         this.targetUrl ="/api/v1/reset/status_check?chargePointId=" + this.dialogData.chargeID
        this.axios.post(this.targetUrl, {
        serviceId: this.serviceId,
        stationId: this.dialogData.stationID,
        chargePointID: this.dialogData.chargeID,
        connectorID: this.dialogData.connectorId,
        //ps: this.cmdType,
        userID: this.currentAccount
      })
      .then(response => {

        if(response.data.status === 0){
          //alert(0)
        if (response.data != null) {
          //alert(response.data.data)
          if(response.data.data === false){
            //alert("有其他充電樁充電中，充電樁目前不可重啟!")
           // alert("異常:"+response.data.msg)
            this.$confirm('提示', {
              message: '異常-'+response.data.msg +':充電樁目前不可重啟，是否強制重啟?',
              confirmButtonText: '確認',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              //this.delStation(index, row.id)
              this.remoteControl(0)
            }).catch(()=> {
              console.log('cancel reset charge point')
            })
          }else{
            //alert(response.data.msg)
            this.remoteControl(0)
          }
        }
        else{
          alert("異常:"+response.data.msg)

        }

        this.getSummaryInfo();

        }
       // this.dialogRemoteControl=false
      }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
        })
      },
      getServiceOptions() {
            this.listLoading = true
            //http://localhost:6783/api/v1/remote/stationidListByserviceId?serviceId=1
            //this.targetUrl =  "api/v1/remote/stationidListByserviceId?serviceId="+this.serviceId
            //this.axios.get(this.targetUrl).then(res => {
            //this.serviceList = res.data      
            //this.serviceOptions=res.data
            //console.log('serviceOptions',this.serviceOptions)
             //})
             //console.log('getServiceOptions')
             this.axios.get('/api/v1/common/station/enabled', { params: this.listQuery }).then(res => {
        this.serviceOptions = res.data
        //console.log('serviceOptions',this.serviceOptions)
      })
             this.listLoading = false
          },
    }
}
</script>
<style lang="scss" scoped>

.dashboard-card {
  background-color: #80c06c;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}

.dashboard-card:hover {
  cursor: pointer;
}

.dashboard-card-no-click {
  background-color: #d87a80;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px;
}

.card-text {
  text-align: right;
  margin-right: 0px;
  margin-bottom: 15px;
  float: right;
}
.card-num {
  font-weight: bold;
  font-size: 30px;
}

.detail:hover {
  cursor: pointer;
}

.card-note {
  font-size: 6px;
}

.card-hidden-note {
  font-size: 6px;
  visibility: hidden;
}
.radio-group {
  margin-bottom: 10px;
}

.radio-option {
  margin-right: 10px;
}


.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
  width: 120px; display: inline-block;
}
.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
.custom-radio {
  width: 150px;
  text-align: left;
}
</style>
