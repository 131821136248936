// 水管家轉導 URL
export const PMS_REDIRECT_URL = Object.freeze({
    'dev': {
        'apiPath': 'http://localhost:8083/api/system/forward?location=',
        'location': 'http://localhost:8083/bms-redirect/'
    },
    'stage': {
        'apiPath': 'https://webpms-stage.water.gov.taipei/api/system/forward?location=',
        'location': '/bms-redirect/'
    },
    'prod': {
        'apiPath': 'https://webpms.water.gov.taipei/api/system/forward?location=',
        'location': '/bms-redirect/'
    },
})

// 水管家轉導 URL
// export const PMS_REDIRECT_URL = Object.freeze({
//     'dev': 'http://localhost:8083/api/system/forward?location=http://localhost:8083/bms-redirect/', // 開發環境
//     'stage': 'https://webpms.water.gov.taipei/api/system/forward?location=/bms-redirect/', // 線上環境 (stage)
// })


// 契約類型
export const CONTRACT_TYPE = ['purchase', 'service', 'project']

// 契約階段
export const CONTRACT_STATE = ['modified', 'confirmed', 'rejected', 'required', 'edited']

// 契約記事類型
export const CONTRACT_LOG_TYPE = Object.freeze({
    'plan': '作業計畫',
    'work': '工作報告',
    'acceptance': '驗測報告',
    'test': '自主檢驗報告',
    'other': '其他'
})

// 監測模組型別
export const MONITOR_TYPE = ['Pressure', 'Quality']

// 裝置型別
export const MATERIAL_TYPE = ['Flow', 'Pressure', 'Quality']

// 傳輸模組型別
export const FAN_TYPE = ['Fan']

// 流量計表種
export const WG_KIND = Object.freeze({
    '01': '螺紋式機械式',
    '06': '奧多曼',
    '09': '螺紋式電子式'
})

// 水栓註記 (For ProfileEdit)
export const AMR_STAGE = Object.freeze({
    'A': 'A-AMR水栓維持人工抄表',
    'Y': 'Y-AMR水栓自動回傳',
    'N': 'N-取消AMR水栓註記',
    // 'U': 'U-AMR水栓訊號未開始回傳'
})

// 水栓註記 (For Profiel Filter)
export const AMR_STAGE_LIST = Object.freeze({
    'A': 'A-AMR水栓維持人工抄表',
    'Y': 'Y-AMR水栓自動回傳',
    'N': 'N-取消AMR水栓註記',
    'U': 'U-AMR水栓訊號未開始回傳'
})

// 水栓註記 (For 即時狀態)
export const AMR_STAGE_AY_LIST = Object.freeze({
    'A': 'A-AMR水栓維持人工抄表',
    'Y': 'Y-AMR水栓自動回傳'
})

// 操作日誌類型 (照目錄順序排)
export const AUDIT_LOG_TYPE = [
    "Login",
    "Role",
    "Account",
    "Profile",
    "Monitor",
    "Fan",
    "ProfileGroup",
    "Announcement",
    "Manufacturer",
    "Contract",
    "Dictionary",
    'Alarm',
    'AlarmRule',
    'Attachment',
    'MeterReplacementPlan',
    'MeterReplacementGroup',
    'Maintenance'
]

// 異動歷程類型
export const CHANGE_LOG_TYPE = [
    "Profile",
    "Contract",
    "Fan",
    "Monitor",
    "Manufacturer"
]

// 系統代碼類型
export const SYS_DICT_CATEGORY = [
    // "WgKindDetail", // 流量計表種 --TOFIX: 資料庫跟後端要刪 並改成wgKind 與其中英對應
    "SupplyType", // 供水方式
    "WgType", // 用水種別
    "ReplacementResult" // 換裝結果
]

// 行政區代碼
export const DISTRICT_CODE = Object.freeze({
    '6300100': '臺北市松山區',
    '6300200': '臺北市信義區',
    '6300300': '臺北市大安區',
    '6300400': '臺北市中山區',
    '6300500': '臺北市中正區',
    '6300600': '臺北市大同區',
    '6300700': '臺北市萬華區',
    '6300800': '臺北市文山區',
    '6300900': '臺北市南港區',
    '6301000': '臺北市內湖區',
    '6301100': '臺北市士林區',
    '6301200': '臺北市北投區',
    '6500200': '新北市三重區',
    '6500300': '新北市中和區',
    '6500400': '新北市永和區',
    '6500600': '新北市新店區',
    '6501100': '新北市汐止區',
    '6501400': '新北市蘆洲區'
})

// 自訂群組類別
export const PROFILE_GROUP_TYPE = [
    "general",
    "community",
    "permission",
    "alarm",
    "group"
]

// 異常案件狀態
export const ALARM_STATE = [
    'opened',
	'processing',
	'pending',
	'verifying',
	'rejected',
	'closed'
]

// 負責人類型
export const OWNER_TYPE = [
    'system',
	'account',
	'unit',
	'manufacturer'
]

// 通知對象
export const NOTIFY_TARGETS = [
    'user',
    'manufacturer',
    'survey',
    'other'
]

// 水號 大區
export const WATERNUM_REGION = Object.freeze({
    '1': '1 一大區',
    '2': '2 二大區',
    '3': '3 三大區',
    '4': '4 四大區',
    'A': 'A 安坑',
    'B': 'B 溝子口',
    'C': 'C 中和',
    'D': 'D 中和2',
    'E': 'E 大直',
    'F': 'F 內湖',
    'H': 'H 新店',
    'I': 'I 松山區',
    'J': 'J 景美',
    'K': 'K 汐止',
    'L': 'L 士林',
    'M': 'M 木柵',
    'N': 'N 南港',
    'P': 'P 石牌',
    'S': 'S 三重',
    'T': 'T 天母',
    'U': 'U 劍潭',
    'V': 'V 陽明山',
    'W': 'W 北投',
    'X': 'X 社子',
    'Y': 'Y 永和'
  })

  // 大區:管理單位
  export const WATERNUM_HEAD_MAP_UNIT = Object.freeze({
    '1': '3',
    '2': '1',
    '3': '4',
    '4': '2',
    'A': '2',
    'B': '3',
    'C': '2',
    'D': '2',
    'E': '1',
    'F': '1',
    'H': '3',
    'I': '1',
    'J': '3',
    'K': '1',
    'L': '5',
    'M': '3',
    'N': '1',
    'P': '5',
    'S': '4',
    'T': '5',
    'U': '5',
    'V': '5',
    'W': '5',
    'X': '4',
    'Y': '2'
  })

// 即時狀態 status
export const RAWDATA_STATUS = Object.freeze({
    'normal': '正常',
    'lost': '斷訊',
    'installed': '已建點未回傳',
    // 'unknown': '未知'
})

// 換表-清冊狀態 state
export const PLAN_STATE = Object.freeze({
    'planning': '預計安裝清冊',
    'scheduled': '派工清冊'
})

export const PROFILE_CANDIDATE_STATE = Object.freeze({
    "MeterPurchaseNumNotFound": "水表契約編號不存在",
    "FanPurchaseNumNotFound": "模組採購編號不存在",
    "UnitNotMatched": "此水號與要求區處不一致",
    "Standby": "新水號，準備建檔（將從水費系統驗證區處是否一致）",
    "Existed": "水號已存在，將再次更新資料",
    "NotFound": "水費系統無此水號",
    "Done": "新水號，完成建檔",
    "ExistedUpdated": "水號已存在，已完成更新資料"
})

export const PROFILE_REPLACEMENT_STATE = Object.freeze({
    Arranging: "請指派派工人員",
    Arranged: "完成派工",
    MeterReplacementStarted: "開始換表",
    MeterReplacementDone: "換表完成",
    MeterReplacementFailed: "換表失敗，待審核",
    FailureAccepting: "發送『接受換裝失敗』指令中",
    FailureAcceptingRejected: "『接受換裝失敗』指令被對方系統拒絕",
    FailureAccepted: "接受換裝失敗",
    FailureRejecting: "發送『拒絕換裝失敗』指令中",
    FailureRejectingRejected: "『拒絕換裝失敗』指令被對方系統拒絕",
    FailureRejected: "拒絕換裝失敗",
    FanReplacementCreating: "發送『建立模組派工單』指令中",
    FanReplacementCreatingRejected: "『建立模組派工單』指令被對方系統拒絕",
    FanReplacementCreated: "建立換模組派工單",
    FanReplacementStarting: "發送『進行施工』指令中",
    FanReplacementStartingRejected: "『進行施工』指令被對方系統拒絕",
    FanReplacementStarted: "開始換模組",
    FanReplacementCancelling: "發送『取消派工』指令中",
    FanReplacementCancellingRejected: "『取消派工』指令被對方系統拒絕",
    FanReplacementCancelled: "取消模組派工單",
    FanReplacementDone: "模組更換成功，待審核",
    FanReplacementFailed: "模組更換失敗，待審核",
    FanReplacementTransMaintaining: "轉維護標",
    FanReplacementTransMaintained: "已轉維護標",
    ContractAssigning: "請求指派派工單契約編號",
    RecallingMeter: "發送『換回一般水表』指令中",
    RecallingMeterRejected: "『換回一般水表』指令被對方系統拒絕",
    RecallMeter: "換回一般水表",
    Closing: "發送『結案』指令中",
    ClosingRejected: "『結案』指令被對方系統拒絕",
    Closed: "結案"
})

// 傳訊方式
export const FAN_ARCHITECTURE = ['standalone', 'aggregation']

export const PROFILE_USAGE = ['public', 'private']

export const WG_BORE = [ 13, 15, 20, 25, 40, 50, 75, 100, 150, 200, 250, 300]

// 節約水量計算公式
export const ALARM_SAVED_VOLUME_FUNCTION = Object.freeze({
    'function1': '公式1：漏水未觸底期間的最低點流量',
    'function2': '公式2：漏水未觸底最低點-已改善未觸底最低點',
    'function3': '公式3：漏水期間日均量-修復後的日均量'
})

export const DAY_OF_WEEK_ON = Object.freeze({
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    0: '日'
})

// 水栓點位狀態
export const PROFILE_STATE = Object.freeze({
    'normal': '正常',
    'disabled': '已停用',
    'deleted': '已刪除'
})

/**    水管家     **/

// 水管家 操作日誌類型
export const PMS_LOG_TYPE = [
    "Login",
    "SaveNote",
    "SaveAlarmRule"
]

// 時間選擇代碼
export const HOURS_CODE = Object.freeze({
    '0': '0時',
    '1': '1時',
    '2': '2時',
    '3': '3時',
    '4': '4時',
    '5': '5時',
    '6': '6時',
    '7': '7時',
    '8': '8時',
    '9': '9時',
    '10': '10時',
    '11': '11時',
})

// 水管家 登入帳號類型
export const ACCOUNT_TYPE = Object.freeze({
    'citizen': '單表用戶登入者',
    'group': '群組用戶主帳號',
    'group_member': '群組用戶子帳號',
    'unit': '分處登入者'
})

export const PMS_ALARM_RULES = [
    'r0302',
    'r0413',
    'r0414',
    'r0415',
    'r0416',
    'r0502',
    'r0703',
    'r0801',
    'r0802'
]

export const GENERIC_CRITERIA_VISIBLE_PGTYPE = [
    'general',
    'permission'
]

//裝收代號
export const DEVICE_RETURN_CODE = Object.freeze({
    'V': '待裝出',
    'L': '檢驗',
    '2': '收回',
    '1': '裝出',
    '8': '壞表',
    '0': '其他',
})